// @flow
import React from "react"
import AceEditor from "react-ace"
import styled from "styled-components"
import "brace/mode/javascript"
import "brace/theme/tomorrow"
import "brace/snippets/javascript"
import { Column, Button, theme } from "./shared"
import Console from "./Console"
import type { ComponentType } from "react"

type Props = {
  isOn: boolean,
  text: string,
  consoleData: { type: "error" | "message", message: string }[],
  handleUpdateEditorText: (() => string) => void,
  evaluateReactionRules: () => void
}

const Wrapper: ComponentType<{}> = styled.div`
  margin-right: 1em;
  min-height: 50vh;
  max-height: 50vh;
`

const StyledEditor: ComponentType<{}> = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  margin-right: 1em;
`

const Editor = (props: Props) => {
  const {
    consoleData,
    isOn,
    text,
    evaluateReactionRules,
    handleUpdateEditorText
  } = props

  return (
    <Column isOn={isOn}>
      <Wrapper>
        <StyledEditor>
          <AceEditor
            mode="javascript"
            theme="tomorrow"
            style={{
              fontFamily: theme.monospace.fontFamily,
              fontSize: theme.monospace.fontSize,
              width: "100%",
              height: "35.5vh",
              borderLeft: `1px solid ${theme.colors.gray}`
            }}
            setOptions={{
              highlightActiveLine: false,
              showLineNumbers: true,
              tabSize: 2,
              useSoftTabs: true,
              useWorker: false
            }}
            wrapEnabled={true}
            value={text}
            onChange={text => handleUpdateEditorText(text)}
          />
          <Console isOn={isOn} data={consoleData} />
          <Button onClick={evaluateReactionRules}>Run</Button>
        </StyledEditor>
      </Wrapper>
    </Column>
  )
}

export default Editor
