// @flow
import { NOTES, cycleNotesFrom } from "./notes"
import { randomInRange } from "../helpers"
import type { Note } from "./notes"

export const MAJOR_CHORD = [0, 4, 7]
export const MINOR_CHORD = [0, 3, 7]
export const DIMINISHED_CHORD = [0, 3, 6]
export const AUGMENTED_CHORD = [0, 4, 8]
export const MAJOR_SEVENTH_CHORD = [0, 4, 7, 11]
export const MINOR_SEVENTH_CHORD = [0, 3, 7, 10]
export const SUSPENDED_SECOND = [0, 2, 7]
export const SUSPENDED_FOURTH = [0, 5, 7]

export type Chord =
  | typeof MAJOR_CHORD
  | typeof MINOR_CHORD
  | typeof DIMINISHED_CHORD
  | typeof AUGMENTED_CHORD
  | typeof MAJOR_SEVENTH_CHORD
  | typeof MINOR_SEVENTH_CHORD
  | typeof SUSPENDED_SECOND
  | typeof SUSPENDED_FOURTH

const CHORDS = [
  MAJOR_CHORD,
  MINOR_CHORD,
  DIMINISHED_CHORD,
  AUGMENTED_CHORD,
  MAJOR_SEVENTH_CHORD,
  MINOR_SEVENTH_CHORD,
  SUSPENDED_SECOND,
  SUSPENDED_FOURTH
]

export const chordFromNote: (Note, Chord) => Chord = (note, chord) =>
  chord.map(step => cycleNotesFrom(note)[step])

export const generateRandomChord = () => {
  const chords = CHORDS.map<Chord>(chord => {
    const notes = NOTES.map(note => chordFromNote(note, chord))
    return notes[randomInRange(0, notes.length - 1)]
  })
  return chords[randomInRange(0, chords.length - 1)]
}
