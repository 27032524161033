// @flow
import React, { useEffect } from "react"
import styled from "styled-components"
import Prism from "prismjs"
import { Column } from "./shared"
import { toString } from "../molecules"
import type { ComponentType } from "react"
import type { Node } from "../models/node"

type Props = {
  isOn: boolean,
  nodes: Node[]
}

const Wrapper: ComponentType<{}> = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGray};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  border-left: 1px solid ${({ theme }) => theme.colors.gray};
  min-height: 40.5vh;
  max-height: 40.5vh;
  overflow: auto;
`

const StyledInspector: ComponentType<{ isOn: boolean }> = styled.div`
  display: flex;
  flex-direction: column;
`

const Inspector = (props: Props) => {
  const { isOn, nodes } = props

  useEffect(() => {
    Prism.highlightAll()
  })

  return (
    <Column isOn={isOn}>
      <Wrapper>
        <StyledInspector isOn={isOn}>
          <pre>
            <code className="language-javascript">
              {toString(nodes.map(({ molecule }) => molecule))}
            </code>
          </pre>
        </StyledInspector>
      </Wrapper>
    </Column>
  )
}

export default Inspector
