// @flow
import React from "react"
import styled from "styled-components"
import logo from "../assets/logo.svg"
import type { ComponentType } from "react"

const StyledHeader: ComponentType<{}> = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  text-align: center;
  width: 100%;
`

const Image: ComponentType<{ src: string }> = styled.img`
  height: 6vh;
  margin: 0 auto;
  padding: 0.5vh 0;
`

const Header = () => {
  return (
    <StyledHeader>
      <Image src={logo} />
    </StyledHeader>
  )
}

export default Header
