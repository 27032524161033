// @flow
import { useState, useEffect } from "react"

export const useLocalStorage = <T>(
  name: string,
  initialValue: T
): [T, (T) => void] => {
  const [value, setValue] = useState(
    JSON.parse(window.localStorage.getItem(name) || initialValue
  ))

  useEffect(() => {
    window.localStorage.setItem(name, JSON.stringify(value))
  }, [name, value])

  return [value, setValue]
}
