// @flow
import { cycle } from "../helpers"

export type Note =
  | "C"
  | "C#"
  | "D"
  | "D#"
  | "E"
  | "F"
  | "F#"
  | "G"
  | "G#"
  | "A"
  | "A#"
  | "B"

export const NOTES: $ReadOnlyArray<Note> = [
  "C",
  "C#",
  "D",
  "D#",
  "E",
  "F",
  "F#",
  "G",
  "G#",
  "A",
  "A#",
  "B"
]

export const cycleNotesFrom = (name: Note) => {
  const index = NOTES.findIndex(note => name === note)
  const range = NOTES.slice(index, 16).concat(NOTES.slice(0, index + 1))
  return cycle(range, 26)
}
