// @flow
import { cycleNotesFrom } from "./notes"
import type { Note } from "./notes"

export const MAJOR_SCALE = [0, 2, 4, 5, 7, 9, 11, 12]
export const MINOR_SCALE = [0, 2, 3, 5, 7, 8, 10, 12]

export type Scale = typeof MAJOR_SCALE | typeof MINOR_SCALE

export const scaleFromKey: (Scale, Note) => Scale = (scale, key) => {
  const notes = cycleNotesFrom(key)
  return scale.map(step => notes[step])
}
