// @flow
import Tone from "tone"
import { extractEffect, extractMolecule } from "./extractMolecule"
import { createOscillator, createEffect, createPolySynth } from "../molecules"
import { randomInRange } from "../helpers"
import type {
  EffectString,
  Molecule,
  Effect,
  Oscillator,
  Frequency,
  Play,
  PolySynth,
  Chord
} from "../molecules"

const UNRESOLVABLE = [null, null]

const isMatch = (first: Molecule, second: Molecule, types: [string, string]) =>
  (first.type === types[0] && second.type === types[1]) ||
  (second.type === types[0] && first.type === types[1])

const isEffectMatch = (
  first: Molecule,
  second: Molecule,
  effectType: EffectString
) =>
  (first.type === "effect" &&
    first.data.effectType === effectType &&
    second.type === "oscillator") ||
  (second.type === "effect" &&
    second.data.effectType === effectType &&
    first.type === "oscillator")

const connected = (oscillator: Oscillator, effect: Effect) =>
  oscillator.data.effects.map(({ id }) => id).includes(effect.id)

export const reduceOscillatorWithFrequency = (
  first: Molecule,
  second: Molecule
) => {
  if (isMatch(first, second, ["oscillator", "frequency"])) {
    let oscillator = extractMolecule<Oscillator>("oscillator", first, second)
    let frequency = extractMolecule<Frequency>("frequency", first, second)

    const {
      id,
      data: { waveform, effects }
    } = oscillator

    return [createOscillator({ id, frequency, waveform, effects }), frequency]
  }

  return UNRESOLVABLE
}

export const reduceOscillatorWithPlay = (first: Molecule, second: Molecule) => {
  if (isMatch(first, second, ["oscillator", "play"])) {
    let oscillator = extractMolecule<Oscillator>("oscillator", first, second)
    let play = extractMolecule<Play>("play", first, second)
    const { waveform, frequency, effects } = oscillator.data

    const effectNodes = effects.map(({ data: { effectType } }) =>
      createEffect(effectType)
    )
    const oscillatorNode = new Tone.Oscillator(440, waveform).toMaster()
    oscillatorNode.chain(...effectNodes, Tone.Master)

    // const { waveform, frequency, effects } = oscillator.data

    // const effectNodes = effects.map(({ data: { effectType } }) =>
    //   createEffect(effectType)
    // )
    // const oscillatorNode = new Tone.PolySynth(4, Tone.Synth, {
    //   oscillator: { type: waveform }
    // }).toMaster()

    // oscillatorNode.chain(...effectNodes, Tone.Master)

    // const minorChords = [
    //   { name: "cMinor", notes: ["C", "Eb", "G"] },
    //   { name: "dMinor", notes: ["D", "F", "A"] },
    //   { name: "fMinor", notes: ["F", "Ab", "C"] },
    //   { name: "gMinor", notes: ["G", "Bb", "D"] },
    //   { name: "aMinor", notes: ["A", "C", "E"] },
    //   { name: "bMinor", notes: ["B", "D", "F#"] }
    // ]
    // const randomChord = minorChords[
    //   randomInRange(0, minorChords.length - 1)
    // ].notes.map(note => `${note}4`)

    oscillatorNode.volume.value = -12
    oscillatorNode.fadeIn = 0.5
    oscillatorNode.start()
    // oscillatorNode.triggerAttackRelease(randomChord, "2n")

    return [oscillator, play]
  }

  return UNRESOLVABLE
}

export const reduceOscillatorWithEffect = (
  type: EffectString,
  first: Molecule,
  second: Molecule
) => {
  if (isEffectMatch(first, second, type)) {
    let oscillator = extractMolecule<Oscillator>("oscillator", first, second)
    let effect = extractEffect(type, first, second)
    const {
      id,
      data: { frequency, waveform, effects }
    } = oscillator
    const newEffects = connected(oscillator, effect)
      ? effects
      : [...effects, effect]

    return [
      createOscillator({ id, frequency, waveform, effects: newEffects }),
      effect
    ]
  }

  return UNRESOLVABLE
}

export const reduceSynthWithChord = (first: Molecule, second: Molecule) => {
  if (isMatch(first, second, ["polySynth", "chord"])) {
    let polySynth = extractMolecule<PolySynth>("polySynth", first, second)
    let chord = extractMolecule<Chord>("chord", first, second)

    const {
      id,
      data: { waveform, effects }
    } = polySynth

    return [createPolySynth({ id, chord, waveform, effects }), chord]
  }

  return UNRESOLVABLE
}

export const reduceSynthWithPlay = (first: Molecule, second: Molecule) => {
  if (isMatch(first, second, ["polySynth", "play"])) {
    let polySynth = extractMolecule<PolySynth>("polySynth", first, second)
    let play = extractMolecule<Play>("play", first, second)
    const { waveform, chord, effects } = polySynth.data
    const { notes } = chord.data

    // const effectNodes = effects.map(({ data: { effectType } }) =>
    //   createEffect(effectType)
    // )
    const polySynthNode = new Tone.PolySynth(4, Tone.Synth, {
      oscillator: { type: waveform }
    }).toMaster()

    // polySynthNode.chain(...effectNodes, Tone.Master)

    console.log(notes)
    polySynthNode.volume.value = -12
    polySynthNode.triggerAttackRelease(notes.map(note => `${note}4`), "1n")

    return [polySynth, play]
  }

  return UNRESOLVABLE
}
