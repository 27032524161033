// @flow
import styled from "styled-components"
import type { ComponentType } from "react"

const Row: ComponentType<{}> = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  min-width: 100%;
  justify-content: left;
  margin: 0;
  padding: 0;
`

export default Row
