// @flow
import { useCallback, useEffect, useRef } from "react"

export const useRequestAnimationFrame = (
  callback: ?() => void,
  fps: number = 0
) => {
  const animationRef = useRef(null)
  const frameRef = useRef(0)

  const tick = useCallback(
    frame => {
      frameRef.current = frameRef.current + 1

      if (frameRef.current >= Math.round(60 / fps)) {
        if (callback) callback()
        frameRef.current = 0
      }

      animationRef.current = window.requestAnimationFrame(tick)
    },
    [callback, fps]
  )

  useEffect(() => {
    animationRef.current = window.requestAnimationFrame(tick)

    return () => window.cancelAnimationFrame(animationRef.current)
  }, [tick])
}
