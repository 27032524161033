// @flow
import styled from "styled-components"
import type { ComponentType } from "react"

const Container: ComponentType<{}> = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100vh;
`

export default Container
