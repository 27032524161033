// @flow
import Tone from "tone"
import { createChorus, COLOR as CHORUS_COLOR } from "./chorus"
import { createReverb, COLOR as REVERB_COLOR } from "./reverb"
import {
  createPingPongDelay,
  COLOR as PING_PONG_DELAY_COLOR
} from "./pingPongDelay"

import type { Chorus } from "./chorus"
import type { Reverb } from "./reverb"
import type { PingPongDelay } from "./pingPongDelay"

export type Effect = Chorus | Reverb | PingPongDelay
export type EffectString = "chorus" | "reverb" | "pingPongDelay"
export type { Chorus, Reverb, PingPongDelay }
export type JSON = {|
  type: "effect",
  effectType: EffectString,
  id: string
|}

const toJSON: Effect => JSON = effect => {
  const {
    type,
    id,
    data: { effectType }
  } = effect
  return { type, effectType, id }
}

export const createEffect: EffectString =>
  | Tone.Chorus
  | Tone.Freeverb
  | Tone.PingPongDelay = effect => {
  switch (effect) {
    case "chorus":
      return new Tone.Chorus().toMaster()
    case "reverb":
      return new Tone.Freeverb().toMaster()
    case "pingPongDelay":
      return new Tone.PingPongDelay().toMaster()
    default:
      throw new Error(`Non-existing molecule type ${effect}`)
  }
}

export {
  createChorus,
  CHORUS_COLOR,
  createReverb,
  REVERB_COLOR,
  createPingPongDelay,
  PING_PONG_DELAY_COLOR,
  toJSON
}
