// @flow
const examples = [
  {
    title: "Simple example",
    content: `const { addRules, ReactionRule, Oscillator, Chorus } = brownianMusic

const simpleRule = new ReactionRule()
simpleRule
  .replace([Oscillator, Chorus])
  .by([Oscillator])
  .if(() => true)

addRules(simpleRule)`
  },
  {
    title: "Conditional example",
    content: `const { addRules, ReactionRule, Oscillator, Chorus } = brownianMusic

const conditionalRule = new ReactionRule()
conditionalRule
  .replace([Oscillator, Chorus])
  .by([Oscillator])
  .if((first, second) => {
    const seconds = new Date().getSeconds()
    return seconds % 2 === 0
  })

addRules(conditionalRule)`
  },
  {
    title: "Side effect example",
    content: `const { addRules, ReactionRule, Oscillator, Frequency, reduceOscillatorWithFrequency } = brownianMusic

const sideEffectRule = new ReactionRule()
sideEffectRule
  .replace([Oscillator, Frequency])
  .by((first, second) => {
    const [newOscillator, frequency] = reduceOscillatorWithFrequency(first, second)
    return [newOscillator, frequency]
  })
  .if(() => true)

addRules(sideEffectRule)`
  },
  {
    title: "Play example",
    content: `const { addRules, ReactionRule, Oscillator, Play, reduceOscillatorWithPlay } = brownianMusic

const playRule = new ReactionRule()
playRule
  .replace([Oscillator, Play])
  .by((first, second) => {
    const [newOscillator, play] = reduceOscillatorWithPlay(first, second)
    return [newOscillator]
  })
  .if(() => true)

addRules(playRule)`
  },
  {
    title: "Multiple rules example",
    content: `const { addRules, ReactionRule, Oscillator, Frequency, Play, reduceOscillatorWithFrequency, reduceOscillatorWithPlay } = brownianMusic

const frequencyRule = new ReactionRule()
frequencyRule
  .replace([Oscillator, Frequency])
  .by((first, second) => {
    const [newOscillator, frequency] = reduceOscillatorWithFrequency(first, second)
    return [newOscillator, frequency]
  })
  .if(() => true)

const playRule = new ReactionRule()
playRule
  .replace([Oscillator, Play])
  .by((first, second) => {
    const [newOscillator, play] = reduceOscillatorWithPlay(first, second)
    return []
  })
  .if(() => true)

addRules(frequencyRule, playRule)`
  },
  {
    title: "Effect example",
    content: `const { addRules, ReactionRule, Oscillator, PingPongDelay, reduceOscillatorWithEffect, extract, extractEff, isEffectInChain } = brownianMusic

const pingPongDelayRule = new ReactionRule()
pingPongDelayRule
  .replace([Oscillator, PingPongDelay])
  .by((first, second) => {
    const [newOscillator, pingPongDelay] = reduceOscillatorWithEffect(PingPongDelay, first, second)
    return [newOscillator]
  })
  .if((first, second) => {
    const oscillator = extract(Oscillator, first, second)
    const pingPongDelay = extractEff(PingPongDelay, first, second)
    const isInChain = pingPongDelay && oscillator && isEffectInChain(oscillator, pingPongDelay)
      return !isInChain
  })

addRules(pingPongDelayRule)`
  },
  {
    title: "JSON example",
    content: `const { addRules, ReactionRule, Play, Oscillator, fetchJSON } = brownianMusic

const fetchRule = new ReactionRule()
fetchRule
  .replace([Oscillator, Play])
  .by([Oscillator])
  .if(() => {
    const json = fetchJSON("http://localhost:4567/todos")
    return json["todos"][0]["name"] === "bar"
  })

addRules(fetchRule)`
  }
]

export default examples
