// @flow
import styled from "styled-components"
import type { ComponentType } from "react"

const Input: ComponentType<{}> = styled.input`
  background-color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSize};
  outline: none;
  text-decoration: none;
  border: 2px solid ${({ theme }) => theme.colors.gray};
  padding: 0.5em;
  width: 100%;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;

  :focus {
    border: 2px solid ${({ theme }) => theme.colors.blue};
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

export default Input
