// @flow
import type {
  Effect,
  EffectString,
  Molecule,
  MoleculeString
} from "../molecules"

export const extractMolecule: <T: Molecule>(
  type: MoleculeString,
  first: Molecule,
  second: Molecule
) => T = (type, first, second) => {
  if (first.type === type) return first
  if (second.type === type) return second
  throw new Error(`Cannot match ${first.type} or ${second.type} with ${type}`)
}

export const extractEffect: (
  type: EffectString,
  first: Molecule,
  second: Molecule
) => Effect = (type, first, second) => {
  if (first.type === "effect" && first.data.effectType === type) return first
  if (second.type === "effect" && second.data.effectType === type) return second
  throw new Error(`Cannot match ${first.type} or ${second.type} with ${type}`)
}

export const extract = (
  type: MoleculeString,
  first: Molecule,
  second: Molecule
) => {
  try {
    return extractMolecule(type, first, second)
  } catch (e) {
    return null
  }
}

export const extractEff = (
  type: EffectString,
  first: Molecule,
  second: Molecule
) => {
  try {
    return extractEffect(type, first, second)
  } catch (e) {
    return null
  }
}
