// @flow
import { randomInRange, uuidv4 } from "../helpers"
import { generateRandomChord } from "../musicTheory"
import type { Note } from "../musicTheory"

export const COLOR = "#FFE119"
const DEFAULT_NOTES = ["C", "E", "G"]
const DEFAULT_OCTAVE = 4
const OCTAVES = [0, 1, 2, 3, 4, 5, 6, 7]
const RADIUS = 25

export const MAJOR_CHORD = [0, 4, 7]
export const MINOR_CHORD = [0, 3, 7]

export type Octave = number

export type ChordParams = {|
  id: string,
  notes: Note[],
  octave: Octave
|}

export type Chord = {|
  +type: "chord",
  +id: string,
  +color: string,
  +text: string,
  +radius: number,
  data: {
    notes: Note[],
    octave: Octave
  }
|}

export type JSON = $ReadOnly<{|
  type: "chord",
  id: string,
  data: {
    notes: Note[],
    octave: Octave
  }
|}>

export const DEFAULT_CHORD: Chord = {
  type: "chord",
  id: uuidv4(),
  color: COLOR,
  text: `${DEFAULT_NOTES.join("")}${DEFAULT_OCTAVE}`,
  radius: RADIUS,
  data: {
    notes: DEFAULT_NOTES,
    octave: DEFAULT_OCTAVE
  }
}

const generateRandomOctave = () => randomInRange(0, OCTAVES.length - 1)

export const createChord = (
  { id, notes, octave }: ChordParams = {
    id: uuidv4(),
    notes: DEFAULT_NOTES,
    octave: DEFAULT_OCTAVE
  }
) => ({
  type: "chord",
  id,
  color: COLOR,
  text: `${notes.join("")}${octave}`,
  radius: RADIUS,
  data: {
    notes,
    octave
  }
})

export const createRandomChord = (
  { id, notes, octave }: ChordParams = {
    id: uuidv4(),
    notes: generateRandomChord(),
    octave: generateRandomOctave()
  }
) => createChord({ id, notes, octave })

export const toJSON: Chord => JSON = chord => {
  const {
    type,
    id,
    data: { notes, octave }
  } = chord

  return {
    type,
    id,
    data: {
      notes,
      octave
    }
  }
}
