// @flow
import React from "react"
import styled from "styled-components"
import type { ComponentType } from "react"

type Props = {
  isOn: boolean,
  data: { type: "error" | "message", message: string }[]
}

const Wrapper: ComponentType<{}> = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGray};
  border: 1px solid ${({ theme }) => theme.colors.gray};
  margin: 0;
  margin-bottom: 1vh;
  min-height: 5vh;
  max-height: 5vh;
  overflow-y: auto;
`

const StyledConsole: ComponentType<{ isOn: boolean }> = styled.div`
  counter-reset: line;
`

const List: ComponentType<{}> = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  min-height: ${({ theme }) => theme.monospace.fontSize};
`

const Line: ComponentType<{ type: "error" | "message" }> = styled.li`
  font-family: ${({ theme }) => theme.monospace.fontFamily};
  font-size: ${({ theme }) => theme.monospace.fontSize};
  line-height: 2.5vh;
  border-left: 10px solid
    ${({ type, theme }) =>
      type === "error" ? theme.colors.red : theme.colors.blue};
  padding: 0 0.5em;

  ::before {
    counter-increment: line;
    content: "(" counter(line) ") ›";
    color: ${({ type, theme }) =>
      type === "error" ? theme.colors.red : theme.colors.blue};
    padding-right: 0.5em;
  }
`

const Console = (props: Props) => {
  const { data, isOn } = props

  const print = data => {
    if (typeof data === "string" || typeof data === "number") return data
    return JSON.stringify(data, null, 2)
  }

  return (
    <Wrapper>
      <StyledConsole isOn={isOn}>
        <List>
          {data.map(({ type, message }, index) => (
            <Line key={index} type={type}>
              {print(message)}
            </Line>
          ))}
        </List>
      </StyledConsole>
    </Wrapper>
  )
}

export default Console
