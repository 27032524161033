// @flow
export const HEIGHT_DIVIDER = 2.25
export const DEFAULT_FPS = 10
export const DEFAULT_MASS = 0.1
export const DEFAULT_RADIUS = 25
export const SPEED = 20
export const NODES = 0
export const INITIAL_RULES = {
  chord: {
    chord: [],
    chorus: [],
    effect: [],
    frequency: [],
    oscillator: [],
    pingPongDelay: [],
    play: [],
    polySynth: [],
    reverb: []
  },
  chorus: {
    chord: [],
    chorus: [],
    effect: [],
    frequency: [],
    oscillator: [],
    pingPongDelay: [],
    play: [],
    polySynth: [],
    reverb: []
  },
  effect: {
    chord: [],
    chorus: [],
    effect: [],
    frequency: [],
    oscillator: [],
    pingPongDelay: [],
    play: [],
    polySynth: [],
    reverb: []
  },
  frequency: {
    chord: [],
    chorus: [],
    effect: [],
    frequency: [],
    oscillator: [],
    pingPongDelay: [],
    play: [],
    polySynth: [],
    reverb: []
  },
  oscillator: {
    chord: [],
    chorus: [],
    effect: [],
    frequency: [],
    oscillator: [],
    pingPongDelay: [],
    play: [],
    polySynth: [],
    reverb: []
  },
  pingPongDelay: {
    chord: [],
    chorus: [],
    effect: [],
    frequency: [],
    oscillator: [],
    pingPongDelay: [],
    play: [],
    polySynth: [],
    reverb: []
  },
  play: {
    chord: [],
    chorus: [],
    effect: [],
    frequency: [],
    oscillator: [],
    pingPongDelay: [],
    play: [],
    polySynth: [],
    reverb: []
  },
  polySynth: {
    chord: [],
    chorus: [],
    effect: [],
    frequency: [],
    oscillator: [],
    pingPongDelay: [],
    play: [],
    polySynth: [],
    reverb: []
  },
  reverb: {
    chord: [],
    chorus: [],
    effect: [],
    frequency: [],
    oscillator: [],
    pingPongDelay: [],
    play: [],
    polySynth: [],
    reverb: []
  }
}
