// @flow
import { useEffect, useState } from "react"
import { HEIGHT_DIVIDER } from "../constants"

export const useDimensions = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight / HEIGHT_DIVIDER)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight / HEIGHT_DIVIDER)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return [width, height]
}
