// @flow
import { uuidv4 } from "../../helpers"

export const COLOR = "#40819A"
const CHORUS_TEXT = "C"
const RADIUS = 25

export type Chorus = {|
  +type: "effect",
  +id: string,
  +color: string,
  +text: string,
  +radius: number,
  data: {
    effectType: "chorus"
  }
|}

export const createChorus = () => {
  return {
    type: "effect",
    id: uuidv4(),
    color: COLOR,
    text: CHORUS_TEXT,
    radius: RADIUS,
    data: {
      effectType: "chorus"
    }
  }
}
