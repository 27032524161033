// @flow
import { uuidv4 } from "../../helpers"

export const COLOR = "#666666"
const REVERB_TEXT = "R"
const RADIUS = 45

const DEFAULT_ROOMSIZE = 0.7
const DEFAULT_DAMPENING = 3000

export type ReverbParams = {|
  roomSize: number,
  dampening: number
|}

export type Reverb = {|
  +type: "effect",
  +id: string,
  +color: string,
  +text: string,
  +radius: number,
  data: {
    effectType: "reverb"
  }
|}

export const createReverb = (
  { roomSize, dampening }: ReverbParams = {
    roomSize: DEFAULT_ROOMSIZE,
    dampening: DEFAULT_DAMPENING
  }
) => {
  return {
    type: "effect",
    id: uuidv4(),
    color: COLOR,
    text: REVERB_TEXT,
    radius: RADIUS,
    data: {
      effectType: "reverb"
    }
  }
}
