// @flow
import { randomInRange } from "../helpers"
import { generateRandomChord } from "../musicTheory"
import {
  createOscillator,
  COLOR as OSCILLATOR_COLOR,
  toJSON as oscillatorToJSON
} from "./oscillator"
import {
  createFrequency,
  COLOR as FREQUENCY_COLOR,
  toJSON as frequencyToJSON
} from "./frequency"
import {
  createChord,
  createRandomChord,
  COLOR as CHORD_COLOR,
  toJSON as chordToJSON
} from "./chord"
import {
  createPolySynth,
  COLOR as POLYSYNTH_COLOR,
  toJSON as polySynthToJSON
} from "./audioSources/polysynth"
import {
  createPlay,
  COLOR as PLAY_COLOR,
  toJSON as playToJSON
} from "./functions/play"
import {
  createEffect,
  createChorus,
  CHORUS_COLOR,
  createReverb,
  REVERB_COLOR,
  createPingPongDelay,
  PING_PONG_DELAY_COLOR,
  toJSON as effectToJSON
} from "./effects"

import type { Oscillator, JSON as OscillatorJSON } from "./oscillator"
import type { PolySynth, JSON as PolySynthJSON } from "./audioSources/polysynth"
import type { Frequency, JSON as FrequencyJSON } from "./frequency"
import type { Chord, JSON as ChordJSON } from "./chord"
import type { Play, JSON as PlayJSON } from "./functions/play"
import type { Effect, JSON as EffectJSON } from "./effects"

export type { Oscillator } from "./oscillator"
export type { PolySynth } from "./audioSources/polysynth"
export type { Frequency } from "./frequency"
export type { Chord } from "./chord"
export type { Play } from "./functions/play"
export type { Effect, EffectString } from "./effects"

export type Molecule =
  | Oscillator
  | Frequency
  | Effect
  | Play
  | Chord
  | PolySynth

export type MoleculeString =
  | "chorus"
  | "chord"
  | "frequency"
  | "oscillator"
  | "pingPongDelay"
  | "play"
  | "polySynth"
  | "reverb"
  | "effect"

export type MoleculeJSON =
  | EffectJSON
  | OscillatorJSON
  | FrequencyJSON
  | ChordJSON
  | PlayJSON
  | PolySynthJSON

const initializers = [
  { type: "oscillator", callback: createOscillator },
  { type: "frequency", callback: createFrequency },
  { type: "chorus", callback: createChorus },
  { type: "reverb", callback: createReverb },
  { type: "pingPongDelay", callback: createPingPongDelay },
  { type: "play", callback: createPlay },
  { type: "chord", callback: createChord },
  { type: "polySynth", callback: createPolySynth }
]

const createMolecule: () => Molecule = () => {
  const randomMolecule = initializers[randomInRange(0, initializers.length - 1)]

  switch (randomMolecule.type) {
    case "oscillator":
    case "chorus":
    case "reverb":
    case "effect":
    case "pingPongDelay":
    case "play":
    case "frequency":
    case "chord":
    case "polySynth":
      return randomMolecule.callback()
    default:
      throw new Error(`Unhandled molecule type: ${randomMolecule.type}`)
  }
}

const toJSON: Molecule => MoleculeJSON = molecule => {
  if (molecule.type === "oscillator") {
    return oscillatorToJSON(molecule)
  }

  if (molecule.type === "frequency") {
    return frequencyToJSON(molecule)
  }

  if (molecule.type === "play") {
    return playToJSON(molecule)
  }

  if (molecule.type === "effect") {
    return effectToJSON(molecule)
  }

  if (molecule.type === "polySynth") {
    return polySynthToJSON(molecule)
  }

  if (molecule.type === "chord") {
    return chordToJSON(molecule)
  }

  throw new Error(`Unhandled molecule type: ${molecule.type}`)
}

export const toString: (Molecule[]) => string = molecules => {
  return JSON.stringify(
    {
      count: molecules.length,
      molecules: molecules.map(toJSON)
    },
    null,
    2
  )
}

export {
  createEffect,
  createChorus,
  CHORUS_COLOR,
  createFrequency,
  FREQUENCY_COLOR,
  createMolecule,
  createOscillator,
  OSCILLATOR_COLOR,
  createPingPongDelay,
  PING_PONG_DELAY_COLOR,
  createPlay,
  PLAY_COLOR,
  createReverb,
  REVERB_COLOR,
  createChord,
  createRandomChord,
  CHORD_COLOR,
  createPolySynth,
  POLYSYNTH_COLOR
}
