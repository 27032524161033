// @flow
import { uuidv4 } from "../../helpers"

export const COLOR = "#F5AE61"
const DELAY_TEXT = "D"
const RADIUS = 25

export type PingPongDelay = {|
  +type: "effect",
  +id: string,
  +color: string,
  +text: string,
  +radius: number,
  data: {
    effectType: "pingPongDelay"
  }
|}

export const createPingPongDelay = () => {
  return {
    type: "effect",
    id: uuidv4(),
    color: COLOR,
    text: DELAY_TEXT,
    radius: RADIUS,
    data: {
      effectType: "pingPongDelay"
    }
  }
}
