// @flow
import Equation from "./equation"
import type { MoleculeString } from "../molecules"
import type { Node } from "../models/node"

export default class ReactionRule {
  equation: Equation

  constructor() {
    this.equation = new Equation()
  }

  replace(molecules: MoleculeString[]) {
    return this.equation.set(molecules)
  }

  run(first: Node, second: Node) {
    return this.equation.run(first, second)
  }
}
