// @flow
import React from "react"
import styled from "styled-components"
import { Dropdown, Row, Column, Input, Button } from "./shared"
import examples from "../examples"
import {
  createRandomChord,
  CHORD_COLOR,
  createChorus,
  CHORUS_COLOR,
  createFrequency,
  FREQUENCY_COLOR,
  createOscillator,
  OSCILLATOR_COLOR,
  createPingPongDelay,
  PING_PONG_DELAY_COLOR,
  createPlay,
  PLAY_COLOR,
  createReverb,
  REVERB_COLOR,
  createPolySynth,
  POLYSYNTH_COLOR
} from "../molecules"
import type { ComponentType } from "react"
import type { Molecule } from "../molecules"

type Props = {
  handleFps: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  fps: number,
  handleStop: (e: SyntheticEvent<HTMLButtonElement>) => void,
  handleClear: (e: SyntheticEvent<HTMLButtonElement>) => void,
  handleInspector: (e: SyntheticEvent<HTMLButtonElement>) => void,
  handleEditor: (e: SyntheticEvent<HTMLButtonElement>) => void,
  handleAddEffect: (effectHandler: () => Molecule) => void,
  handleUpdateEditorText: string => void
}

const Wrapper: ComponentType<{}> = styled.div`
  margin: 0 1em;
  min-height: 50vh;
  max-height: 50vh;
`

const Header: ComponentType<{}> = styled.h2`
  margin: 0 0.25vw 1vh 0.25vw;
`

const Label: ComponentType<{}> = styled.label`
  font-weight: bold;
  padding: 0.5em 0;

  :first-child {
    display: block;
  }
`

const Divider: ComponentType<{}> = styled.hr`
  margin: 0.5vh 0.25vw 1.5vh 0.25vw;
  border: none;
`

const Controls = (props: Props) => {
  const { handleUpdateEditorText } = props
  const effectHandlers = [
    {
      text: "Add oscillator",
      color: OSCILLATOR_COLOR,
      handler: createOscillator
    },
    { text: "Add frequency", color: FREQUENCY_COLOR, handler: createFrequency },
    { text: "Add chorus", color: CHORUS_COLOR, handler: createChorus },
    { text: "Add reverb", color: REVERB_COLOR, handler: createReverb },
    {
      text: "Add ping pong delay",
      color: PING_PONG_DELAY_COLOR,
      handler: createPingPongDelay
    },
    { text: "Add play", color: PLAY_COLOR, handler: createPlay },
    { text: "Add chord", color: CHORD_COLOR, handler: createRandomChord },
    { text: "Add poly synth", color: POLYSYNTH_COLOR, handler: createPolySynth }
  ]

  return (
    <Column>
      <Wrapper>
        <Row>
          <Dropdown
            header="Explore examples..."
            data={examples.map(example => ({
              ...example,
              onClick: () => handleUpdateEditorText(example.content)
            }))}
          />
        </Row>
        <Divider />
        <Row>
          <Header>Controls</Header>
        </Row>
        <Row>
          <Column>
            <Label>Speed</Label>
            <Input
              type="number"
              onChange={props.handleFps}
              value={props.fps}
              step="0.25"
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <Button onClick={props.handleStop}>Stop</Button>
          </Column>
          <Column>
            <Button onClick={props.handleClear}>Clear</Button>
          </Column>
          <Column>
            <Button onClick={props.handleInspector}>Toggle inspector</Button>
          </Column>
          <Column>
            <Button onClick={props.handleEditor}>Toggle editor</Button>
          </Column>
        </Row>
        <Row>
          {effectHandlers.map((effectHandler, index) => {
            return (
              <Column key={index}>
                <Button
                  color={effectHandler.color}
                  onClick={() => props.handleAddEffect(effectHandler.handler)}
                >
                  {effectHandler.text}
                </Button>
              </Column>
            )
          })}
        </Row>
      </Wrapper>
    </Column>
  )
}

export default Controls
