// @flow
import type { Effect, Oscillator, Chord } from "../molecules"
import {
  isChordInKey as checkChordInKey,
  chordFromNote,
  MINOR_CHORD
} from "../musicTheory"
import type { Note } from "../musicTheory"

type ConsoleData = { type: "error" | "message", message: string }[]

export const effectChainLength: (
  oscillator: Oscillator
) => number = oscillator => oscillator && oscillator.data.effects.length

export const isEffectInChain: (
  oscillator: Oscillator,
  effect: Effect
) => boolean = (oscillator, effect) => {
  const {
    data: { effects }
  } = oscillator
  const {
    data: { effectType }
  } = effect

  return effects.some(effect => effectType)
}

export const requestJSON = (
  setConsoleData: ((ConsoleData) => ConsoleData) => void
) => {
  return (url: string) => {
    try {
      const request = new XMLHttpRequest()
      request.open("GET", url, false)
      request.send(null)
      return JSON.parse(request.responseText)
    } catch (e) {
      setConsoleData(prevState => [
        ...prevState,
        { type: "error", message: `ERROR: ${e.message}` }
      ])
      return false
    }
  }
}

const isChordInKey = (chord: Chord, note: Note, key: "major" | "minor") => {
  const { notes } = chord.data
  return checkChordInKey(notes, note, key)
}

export { isChordInKey, chordFromNote, MINOR_CHORD }
