// @flow
import React, { useEffect, useState } from "react"
import Canvas from "./Canvas"
import Controls from "./Controls"
import Editor from "./Editor"
import Header from "./Header"
import Inspector from "./Inspector"
import { Row, Container } from "./shared"
import { DEFAULT_FPS, INITIAL_RULES } from "../constants"
import {
  createNode,
  updateNode,
  lookupType,
  checkEffectTypes,
  checkMatch
} from "../models/node"
import { resolveCollision, isColliding } from "../models/node"
import { useDimensions, useLocalStorage } from "../hooks"
import * as gamma from "../gamma"
import type { Molecule } from "../molecules"
import type { Node } from "../models/node"

const App = () => {
  const [fps, setFps] = useLocalStorage("fps", DEFAULT_FPS)
  const [nodes, setNodes] = useState([])
  const [queuedNodes, setQueuedNodes] = useState([])
  const [width, height] = useDimensions()
  const [isInspectorOn, setIsInspectorOn] = useLocalStorage("inspector", false)
  const [isEditorOn, setIsEditorOn] = useLocalStorage("editor", true)
  const [editorText, setEditorText] = useState("")
  const [reactionRules, setReactionRules] = useState(INITIAL_RULES)
  const [consoleData, setConsoleData] = useState([])

  useEffect(() => {
    const addRules = (...rules: []) => {
      updateConsoleText(`INFO: Added ${rules.length} reaction rules.`)
      setReactionRules(INITIAL_RULES)

      const initialState = {
        chord: {
          chord: [],
          chorus: [],
          effect: [],
          frequency: [],
          oscillator: [],
          pingPongDelay: [],
          play: [],
          polySynth: [],
          reverb: []
        },
        chorus: {
          chord: [],
          chorus: [],
          effect: [],
          frequency: [],
          oscillator: [],
          pingPongDelay: [],
          play: [],
          polySynth: [],
          reverb: []
        },
        effect: {
          chord: [],
          chorus: [],
          effect: [],
          frequency: [],
          oscillator: [],
          pingPongDelay: [],
          play: [],
          polySynth: [],
          reverb: []
        },
        frequency: {
          chord: [],
          chorus: [],
          effect: [],
          frequency: [],
          oscillator: [],
          pingPongDelay: [],
          play: [],
          polySynth: [],
          reverb: []
        },
        oscillator: {
          chord: [],
          chorus: [],
          effect: [],
          frequency: [],
          oscillator: [],
          pingPongDelay: [],
          play: [],
          polySynth: [],
          reverb: []
        },
        pingPongDelay: {
          chord: [],
          chorus: [],
          effect: [],
          frequency: [],
          oscillator: [],
          pingPongDelay: [],
          play: [],
          polySynth: [],
          reverb: []
        },
        play: {
          chord: [],
          chorus: [],
          effect: [],
          frequency: [],
          oscillator: [],
          pingPongDelay: [],
          play: [],
          polySynth: [],
          reverb: []
        },
        polySynth: {
          chord: [],
          chorus: [],
          effect: [],
          frequency: [],
          oscillator: [],
          pingPongDelay: [],
          play: [],
          polySynth: [],
          reverb: []
        },
        reverb: {
          chord: [],
          chorus: [],
          effect: [],
          frequency: [],
          oscillator: [],
          pingPongDelay: [],
          play: [],
          polySynth: [],
          reverb: []
        }
      }

      const newRules = rules.reduce((acc, rule) => {
        const {
          equation: { molecules }
        } = rule
        const [first, second] = molecules.sort()
        console.log(first, second)

        acc[first][second] = acc[first][second]
          ? [...acc[first][second], rule]
          : [acc, rule]
        return acc
      }, initialState)

      setReactionRules(newRules)
    }

    window.brownianMusic = {
      addRules,
      fetchJSON: gamma.requestJSON(setConsoleData),
      ...gamma
    }
    window.print = updateConsoleText
  }, [reactionRules])

  const updateConsoleText = (message: string) => {
    setConsoleData(prevState => [...prevState, { type: "message", message }])
  }

  const findReactionRules = (firstNode: Node, secondNode: Node) => {
    const [first, second] = lookupType(firstNode, secondNode).sort()
    const rules = reactionRules[first][second].filter(element => element)
    if (!rules[0]) return []

    return rules
  }

  const tick = () => {
    const newNodes: Node[] = [...queuedNodes, ...nodes].map(node =>
      updateNode(node, width, height)
    )

    for (let i = 0; i < newNodes.length - 1; i++) {
      for (let j = i + 1; j < newNodes.length; j++) {
        if (isColliding(newNodes[i], newNodes[j])) {
          const { v1, v2 } = resolveCollision(newNodes[i], newNodes[j])
          newNodes[i].velocity = v1
          newNodes[j].velocity = v2

          const rules = findReactionRules(newNodes[i], newNodes[j])

          rules.forEach(reactionRule => {
            const {
              molecules,
              result: { result, resolvable }
            } = reactionRule.run(newNodes[i], newNodes[j])

            const isMatch = checkMatch(molecules, newNodes[i], newNodes[j])

            // Remove both molecules
            if (resolvable && result.length === 0 && isMatch) {
              newNodes[j].active = false
              newNodes[i].active = false
            }

            // Replace both
            if (resolvable && result.length === 2 && isMatch) {
              if (result[0].type === newNodes[i].molecule.type) {
                newNodes[i].molecule = result[0]
                newNodes[j].molecule = result[1]
              } else {
                newNodes[j].molecule = result[0]
                newNodes[i].molecule = result[1]
              }
            }

            // Remove one
            if (resolvable && result.length === 1 && isMatch) {
              if (result[0].type === newNodes[i].molecule.type) {
                newNodes[i].molecule = result[0]
                newNodes[j].active = false
              } else if (result[0].type === newNodes[j].molecule.type) {
                newNodes[j].molecule = result[0]
                newNodes[i].active = false
              } else {
                // No molecule match
              }
            }
          })
        }
      }
    }

    setQueuedNodes([])
    setNodes(newNodes)
  }

  const handleFps = (e: SyntheticInputEvent<HTMLInputElement>) => {
    e.preventDefault()
    setFps(Number(e.currentTarget.value))
  }

  const handleStop = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setFps(0)
  }

  const handleClear = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setNodes([])
  }

  const handleInspector = (e: SyntheticEvent<HTMLButtonElement>) => {
    setIsInspectorOn(!isInspectorOn)
  }

  const handleEditor = (e: SyntheticEvent<HTMLButtonElement>) => {
    setIsEditorOn(!isEditorOn)
  }

  const handleAddEffect = (handleEffect: () => Molecule) => {
    const node = createNode(nodes)
    const effect = handleEffect()
    const newNode = {
      ...node,
      color: effect.color,
      value: effect.text,
      molecule: effect
    }
    setQueuedNodes([...queuedNodes, newNode])
  }

  const evaluateReactionRules = () => {
    setConsoleData([])
    try {
      // eslint-disable-next-line
      new Function(`
        const { brownianMusic } = window
        ${editorText}
    `)()
    } catch (e) {
      setConsoleData(prevState => [
        ...prevState,
        { type: "error", message: `ERROR: ${e.message}` }
      ])
    }
  }

  return (
    <Container>
      <Row>
        <Canvas
          callback={tick}
          fps={fps}
          height={height}
          width={width}
          nodes={nodes}
        />
      </Row>
      <Row>
        <Header />
      </Row>
      <Row>
        <Controls
          handleFps={handleFps}
          fps={fps}
          handleStop={handleStop}
          handleClear={handleClear}
          handleInspector={handleInspector}
          handleEditor={handleEditor}
          handleAddEffect={handleAddEffect}
          handleUpdateEditorText={setEditorText}
        />
        <Editor
          isOn={isEditorOn}
          text={editorText}
          consoleData={consoleData}
          handleUpdateEditorText={setEditorText}
          evaluateReactionRules={evaluateReactionRules}
        />
        <Inspector isOn={isInspectorOn} nodes={nodes} />
      </Row>
    </Container>
  )
}

export default App
