// @flow
import React from "react"
import ReactDOM from "react-dom"
import { ThemeProvider } from "styled-components"
import theme from "./components/shared/theme"
import GlobalStyle from "./components/shared/GlobalStyle"
import App from "./components/App"
import * as serviceWorker from "./serviceWorker"

const root = document.getElementById("root")
if (root !== null) {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <App />
      </>
    </ThemeProvider>,
    root
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
