// @flow
import { uuidv4, randomInRange } from "../helpers"

export const COLOR = "#46B26E"
const DEFAULT_NOTE = "A4"
const RADIUS = 25

export const NOTES = ["A", "A#", "B", "C", "C#", "D", "D#", "E", "F", "G", "G#"]
export const OCTAVES = [...Array(5).keys()].map<number>(octave => octave + 1)

const randomNote = () => {
  const note = NOTES[randomInRange(0, NOTES.length - 1)]
  const octave = OCTAVES[randomInRange(0, OCTAVES.length - 1)]
  return `${note}${octave}`
}

export type FrequencyParams = {| id: string, frequency: string |}

export type Frequency = {|
  +type: "frequency",
  +id: string,
  +color: string,
  +text: string,
  +radius: number,
  data: {
    frequency: string
  }
|}

export type JSON = $ReadOnly<{|
  type: "frequency",
  id: string,
  data: { frequency: string }
|}>

export const DEFAULT_FREQUENCY: Frequency = {
  type: "frequency",
  id: uuidv4(),
  color: COLOR,
  text: DEFAULT_NOTE,
  radius: RADIUS,
  data: {
    frequency: DEFAULT_NOTE
  }
}

export const createFrequency = (
  { id, frequency }: FrequencyParams = { id: uuidv4(), frequency: randomNote() }
) => {
  return {
    type: "frequency",
    id,
    color: COLOR,
    text: frequency,
    radius: RADIUS,
    data: {
      frequency: frequency
    }
  }
}

export const toJSON: Frequency => JSON = frequency => {
  const { type, id, data } = frequency
  return { type, id, data }
}
