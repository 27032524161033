// @flow
import { uuidv4 } from "../helpers"
import { DEFAULT_FREQUENCY } from "./frequency"
import { toJSON as frequencyToJSON } from "./frequency"
import { toJSON as effectToJSON } from "./effects"
import type { Frequency, JSON as FrequencyJSON } from "./frequency"
import type { Effect, JSON as EffectJSON } from "./effects"

export const COLOR = "#F57B61"
const OSCILLATOR_TEXT = "O"
const RADIUS = 45
const DEFAULT_WAVEFORM = "sine"
const DEFAULT_EFFECTS = []

export type Waveform = "sine" | "square" | "sawtooth" | "triangle"

export type OscillatorParams = {|
  id: string,
  frequency: Frequency,
  waveform: Waveform,
  effects: Effect[]
|}

export type Oscillator = {|
  +type: "oscillator",
  +id: string,
  +color: string,
  +text: string,
  +radius: number,
  data: {
    frequency: Frequency,
    waveform: Waveform,
    effects: Effect[]
  }
|}

export type JSON = $ReadOnly<{|
  type: "oscillator",
  id: string,
  data: {|
    frequency: FrequencyJSON,
    waveform: Waveform,
    effects: EffectJSON[]
  |}
|}>

export const createOscillator = (
  { id, frequency, waveform, effects }: OscillatorParams = {
    id: uuidv4(),
    frequency: DEFAULT_FREQUENCY,
    waveform: DEFAULT_WAVEFORM,
    effects: DEFAULT_EFFECTS
  }
) => ({
  type: "oscillator",
  id,
  color: COLOR,
  text: OSCILLATOR_TEXT,
  radius: RADIUS,
  data: {
    frequency,
    waveform,
    effects
  }
})

export const toJSON: Oscillator => JSON = oscillator => {
  const {
    type,
    id,
    data: { waveform, frequency, effects }
  } = oscillator

  return {
    type,
    id,
    data: {
      waveform,
      frequency: frequencyToJSON(frequency),
      effects: effects.map(effectToJSON)
    }
  }
}
