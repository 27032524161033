// @flow
import type { Molecule, MoleculeString } from "../molecules"
import type { Node } from "../models/node"

export const Chord = "chord"
export const Chorus = "chorus"
export const Frequency = "frequency"
export const Oscillator = "oscillator"
export const PingPongDelay = "pingPongDelay"
export const Play = "play"
export const PolySynth = "polySynth"
export const Reverb = "reverb"

type Replace =
  | MoleculeString[]
  | ((Molecule, Molecule) => [null, null] | Molecule[])

export default class Equation {
  molecules: MoleculeString[]
  replace: Replace
  result: {}
  condition: (Molecule, Molecule) => boolean

  constructor() {
    this.molecules = []
    this.replace = []
    this.result = {}
    this.condition = () => false
  }

  set(molecules: MoleculeString[]) {
    this.molecules = molecules
    return this
  }

  by(molecules: Replace) {
    this.replace = molecules
    return this
  }

  if(condition: (first: Molecule, second: Molecule) => boolean) {
    this.condition = condition
  }

  run(first: Node, second: Node) {
    if (!first || !second) return this

    if (this.condition(first.molecule, second.molecule)) {
      if (typeof this.replace === "function") {
        const result = this.replace(first.molecule, second.molecule)
        this.result = { resolvable: true, result }
      }

      if (Array.isArray(this.replace)) {
        const result = [first, second]
          .filter(({ molecule }) => {
            return molecule.type === "effect"
              ? Array.isArray(this.replace) &&
                  this.replace.includes(molecule.data.effectType)
              : Array.isArray(this.replace) &&
                  this.replace.includes(molecule.type)
          })
          .map(({ molecule }) => molecule)
        this.result = { resolvable: true, result }
      }

      return this
    }

    this.result = { resolvable: false, result: this.molecules }
    return this
  }
}
