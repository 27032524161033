// @flow
import styled from "styled-components"
import type { ComponentType } from "react"

const Button: ComponentType<{ color?: string }> = styled.button`
  background: ${({ theme, color }) => (color ? color : theme.colors.blue)};
  font-family: ${theme => theme.fontFamily};
  font-size: ${({ theme }) => theme.button.fontSize};
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  padding: 1.5vh 2vh;
  border: 0px solid ${({ theme }) => theme.colors.transparent};
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
`

export default Button
