// @flow
import * as React from "react"
import styled from "styled-components"
import type { ComponentType } from "react"

type Props = { isOn?: boolean, children?: React.Node }

const StyledColumn: ComponentType<{ isOn?: boolean }> = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 1;
  margin: 0 0.25vw 1vh 0.25vw;
  padding: 0;
  display: ${({ isOn }) => (isOn ? "block" : "none")};
`

const Column = (props: Props) => {
  const { isOn, children } = props
  return <StyledColumn isOn={isOn}>{children}</StyledColumn>
}

Column.defaultProps = { isOn: true }

export default Column
