// @flow
const theme = {
  backgroundColor: "#FFF",
  fontFamily: `-apple-system, BlinkMacSystemFont, 'HelveticaNeue-Light',
    'Helvetica Neue', Arial, sans-serif`,
  fontSize: "1.1rem",
  monospace: {
    fontFamily: "'Fira Mono', 'Menlo', monospace",
    fontSize: "1.1rem"
  },
  button: {
    fontSize: "0.9rem"
  },
  colors: {
    white: "#FFF",
    lightGray: "#F8F8FA",
    gray: "#EFEFEF",
    lightBlue: "#BFE0FF",
    darkGray: "#E3E3E3",
    blue: "#0084FF",
    darkBlue: "#adcbe3",
    transparent: "rgba(0, 0, 0, 0)",
    black: "#4D4D4C",
    red: "#fa3c4c"
  }
}

export default theme
