// @flow
import {
  MAJOR_CHORD,
  MINOR_CHORD,
  DIMINISHED_CHORD,
  chordFromNote
} from "./chords"
import { MAJOR_SCALE, MINOR_SCALE, scaleFromKey } from "./scales"
import type { Chord } from "./chords"
import type { Note } from "./notes"

export const MAJOR_KEY = [
  MAJOR_CHORD,
  MINOR_CHORD,
  MINOR_CHORD,
  MAJOR_CHORD,
  MAJOR_CHORD,
  MINOR_CHORD,
  DIMINISHED_CHORD,
  MAJOR_CHORD
]

export const MINOR_KEY = [
  MINOR_CHORD,
  DIMINISHED_CHORD,
  MAJOR_CHORD,
  MINOR_CHORD,
  MINOR_CHORD,
  MAJOR_CHORD,
  MAJOR_CHORD,
  MINOR_CHORD
]

export type Key = typeof MAJOR_KEY | typeof MINOR_KEY
type KeyString = "major" | "minor"

const keys = {
  major: { scale: MAJOR_SCALE, key: MAJOR_KEY },
  minor: { scale: MINOR_SCALE, key: MINOR_KEY }
}

export const chordsInKey: (Note, KeyString) => Chord[] = (note, key) => {
  const notes = scaleFromKey(keys[key]["scale"], note)
  const chords = keys[key]["key"].map((chord, index) =>
    chordFromNote(notes[index], chord)
  )
  return chords
}

export const isChordInKey: (Chord, Note, KeyString) => boolean = (
  chord,
  note,
  key
) => {
  const chords = chordsInKey(note, key)
  return JSON.stringify(chords).includes(JSON.stringify(chord))
}
